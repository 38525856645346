<style lang="scss">
.gutgemacht {
  /*[class*='col-'] {
      justify-content: center;
      align-self:center;
    }*/
  .gg-headline {
    font-size: 160%;
  }
  .portfolio-box {
    margin-top: 50px;
    [class*='col-'] {
      justify-items: center;
      align-self: center;
    }
  }
  .info-box-fotos {
    img {
      display: block;
      max-width: 150px;
      margin: 0 auto;
    }
    .txt {
      padding: 15px;
      background: $turquoise;
      color: #fff;
    }
  }
  .bild-box {
    width: 100%;
    img {
      display: block;
      margin: 20px auto;
    }
  }
  h2 {
    margin-top: 0;
  }
  ul {
    margin: 10px 0 !important;
    li {
      margin: 5px 0 !important;
    }
  }
  .preis-box {
    padding: 5px 0;
    margin-bottom: 10px;

    span {
      display: inline-block;
      font-style: italic;
      padding: 8px 10px;
      border-top: 1px solid #eee;
      border-bottom: 3px solid #eee;
      strong {
        display: inline-block;

        font-weight: 400;
        margin-left: 5px;
      }
    }
  }
  .content-link-mehrinfo {
    transition: all 0.2s ease;

    background: $blue-darker;
    padding: 10px 15px;
    display: inline-block;
    color: #fff;
    span {
      position: relative;
      top: 5px;
      display: inline-block;
      margin-right: 5px;
    }
    &:hover {
      background: $blue-dark;
    }
  }
  .collapsible-container {
    border: 0;
  }
}
</style>

<template>
  <div class="content gutgemacht container">
    <h1>gutgemacht.at</h1>
    <p class="intro-txt"><strong>Ihr Angebot ist derzeit sehr gefragt!</strong><br />Gerade das heurige Jahr hat gezeigt, wie sehr Kunden Leistungen regionaler Unternehmen schätzen. Grund für uns, unser regionales Empfehlungsportal gutgemacht.at noch attraktiver zu machen. Grund für Sie, diesen starken regionalen Fokus zu nutzen und wieder mit gutgemacht.at durchzustarten.</p>

    <div class="portfolio-box">
      <div class="row">
        <div class="col-lg-6 order-lg-2">
          <div class="bild-box">
            <img src="@/assets/img/img-content/digital/d-gutgemacht.jpg" alt="gutgemacht.at" class="img-fluid shadow" />
          </div>
        </div>
        <div class="col-lg-6">
          <h2>Die besten Unternehmen sind die mit den zufriedensten Kunden.</h2>
          <p>Wir bringen Mundpropaganda und Weiterempfehlungen von der Offline- in die Online-Welt. Mit gutgemacht.at schaffen Sie durch Kundenbewertungen Vertrauen, verbessern Ihre Auffindbarkeit auf Google und machen potenzielle Neukunden so auf Sie aufmerksam.</p>
          <div class="preis-box">
            <span>Tarif ab: <strong>79,00 </strong></span>
          </div>
          <CollapsibleButton link="gutgemacht-collapse" class="content-link-mehrinfo"><span class="material-icons">arrow_right_alt</span>Alle Informationen zu Gut Gemacht.</CollapsibleButton>
        </div>
      </div>
    </div>
    <Collapsible link="gutgemacht-collapse">
      <div class="row margin-50-0">
        <div class="col-lg-6 justify-content-center align-self-center">
          <h2>gutgemacht.at Premium-Eintrag</h2>
          <p>Wir machen potenzielle Kunden auf Sie aufmerksam.</p>
          <h4>Umfassende Unternehmenspräsentation</h4>
          <ul class="content-ul">
            <li>Suchmaschinenoptimierte Unternehmensvorstellung</li>
            <li>Auflistung von bis zu 7 Unternehmensleistungen</li>
            <li>Abbildung von bis zu 10 Unternehmensfotos</li>
            <li>Platzierung des Unternehmens in bis zu 5 Branchen</li>
            <li>Login-Bereich für Ihre Erfolgsstatistik</li>
          </ul>
        </div>
        <div class="col-lg-6 justify-content-center align-self-center">
          <div class="bild-box">
            <img src="@/assets/img/img-content/digital/gg-devices.png" alt="gutgemacht.at - Devices" class="img-fluid" />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 justify-content-center align-self-center order-lg-2">
          <h4>Empfehlungsmarketing-Paket</h4>
          <ul class="content-ul">
            <li>100 Stk. personalisierte Bewertungskärtchen inkl. Eingabeservice</li>
            <li>Kommentarfunktion für Ihre erhaltenen Bewertungen</li>
            <li>E-Mail Benachrichtigung bei jeder neuen Bewertung</li>
            <li>Widget für die Einbindung der Bewertungen auf Ihrer Website</li>
          </ul>
        </div>
        <div class="col-lg-6 justify-content-center align-self-center">
          <div class="bild-box">
            <img src="@/assets/img/img-content/digital/gg-blaetter.png" alt="gutgemacht.at - Empfehlung" class="img-fluid" />
          </div>
        </div>
      </div>

      <div class="tabelle-box tabelle-width-50">
        <div class="headline">
          1 Jahr gutgemacht.at Premium
        </div>
        <div class="row head-row">
          <div class="col-6 text-align-left">Zahlung</div>
          <div class="col-6 text-align-right">Premium Eintrag</div>
        </div>
        <div class="row content-row">
          <div class="col-6 text-align-left">Zahlung monatlich</div>
          <div class="col-6 text-align-right">79,00</div>
        </div>
        <div class="row content-row">
          <div class="col-6 text-align-left">Zahlung einmalig</div>
          <div class="col-6 text-align-right">948,00</div>
        </div>
      </div>
      <div class="info-txt-preis">Alle Preise verstehen sich in Euro, exklusive 20 % Umsatzsteuer.</div>
    </Collapsible>
    <div class="download-pdf">
      <div class="line"><span>Download</span></div>
      <a href="../../pdf/digital/0061-1901-Premiumeintrag-gutgemacht_Flyer.pdf" target="_blank">
        <div class="row">
          <div class="col-8 justify-content-center align-self-center">
            Tarif gutgemacht.at
          </div>
          <div class="col-4 justify-content-end align-self-center text-right">
            <span>487 kb // PDF</span>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CollapsibleButton from '@/components/util/CollapsibleButton.vue';
import Collapsible from '@/components/util/Collapsible.vue';

export default defineComponent({
  name: 'Gut Gemacht',
  components: {
    CollapsibleButton,
    Collapsible,
  },
});
</script>
